/* @import "index.css"; */

.App {
  text-align: center;
  margin: 0;
  color: var(--colorSurface);
}

.App-logo {
  border-radius: 50%;
  margin: 10vmin 0;
  border-color: var(--colorBackground);
  height: 70%;
}

.App-logo:hover {
  cursor: pointer;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

blockquote {
  border-left: 10px solid var(--colorSurface);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: var(--colorSurface);
  content: open-quote;
  font-size: 2em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}


.button {
  background-color: var(--colorPrimary);
  color: var(--colorAccent);
  border-color: var(--colorPrimary);
  box-shadow: 2em;
  padding: 2px 14px;
  cursor: pointer;
  font-size: 0.75em;
}

.button.small {
  width: 3em
}

.button.medium {
  width: 6em
}

.button.large {
  width: 9em
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input.inputBlank {
  all: inherit;
  border: none;
  border-width: none;
  -webkit-writing-mode: horizontal-tb;
  writing-mode: horizontal-tb;
  text-rendering: auto;
  color: var(--colorPrimary);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: end;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  background-color: var(--colorBackground);
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0;
  font: inherit;
  padding: none;
}

.spanIndex {
  margin: 0 2em;
  color: var(--colorSecondary);
}